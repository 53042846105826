.gs-unit-true-weapons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;

  &-title {
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 900;
  }

  .gs-unit-true-weapon {
    display: flex;
    width: 100%;
    gap: 10px;

    img {
      width: 60px;
      height: 60px;

      &:hover {
        cursor: pointer;
        box-shadow: #ffffff 0px 0px 10px;
      }
    }

    span {
      line-height: 55px;
      display: block;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
}
