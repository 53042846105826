.gs-form-field-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;

  .gs-basic-form-field {
    flex: 1;
  }
}
