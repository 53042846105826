.gs-user-unit-all-stats-container {
  display: grid;
  grid-template-columns: 160px auto; // Automatically size the first column to fit the longest item, and the same for the second column
  gap: 10px; // Adjust the space between rows and columns as needed
  align-items: center; // This centers the items vertically
  display: grid;
  width: 100%;
  text-align: start;

  .gs-data-display-row {
    display: contents; // Makes the container "disappear", treating its children as direct descendants of the grid

    strong {
      text-align: right; // Aligns the name to the right
    }
  }
}
