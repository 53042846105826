.gs-flash-messages-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 4px;
  position: absolute;
  width: 100%;
  z-index: 300;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  top: 0;

  .gs-flash-message {
    position: relative;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    min-width: 40%;
    max-width: 40%;
    word-wrap: break-word;
    word-break: break-word;
    cursor: pointer;
  }

  .gs-alert-success {
    color: var(--alert-success-txt-color);
    background-color: var(--alert-success-color);
    border-color: var(--alert-success-border-color);
  }

  .gs-alert-info {
    color: var(--alert-info-txt-color);
    background-color: var(--alert-info-color);
    border-color: var(--alert-info-border-color);
  }

  .gs-alert-warning {
    color: var(--alert-warning-txt-color);
    background-color: var(--alert-warning-color);
    border-color: var(--alert-warning-border-color);
  }

  .gs-alert-danger {
    color: var(--alert-danger-txt-color);
    background-color: var(--alert-danger-color);
    border-color: var(--alert-danger-border-color);
  }
}
