.gs-equipment-slot-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  margin: 0;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding-inline-start: 0px;
  margin-top: 10px;
  li {
    all: unset;
    display: flex;

    .gs-equipment-slot-equip {
      width: 40px;
      height: 40px;
    }

    .gs-equipment-slot-lb {
      height: 30px;
      width: 30px;
      margin-top: 4px;
    }

    img {

    }
  }
}
