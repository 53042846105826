$accent-color: #48535A;
$accent-color-darker: darken($accent-color, 1%);

:root {
  --primary-color: #0F0F0F;
  --secondary-color: #2D2F33;
  --accent-color: #48535A;
  --accent-color-darker: $accent-color-darker;
  --tertiary-color: #79848C;
  --text-color: #ececec;
  --active-text-color: #ffffff;
  --text-input-color: #0F0F0F;
  --text-hover-color: #BCBCBC;
  --submit-btn-color: #222222;
  --background-color: #2D2F33;
  --button-postive: #00A97F;
  --badge-color: #464362;
  --tas-text-color: #E8D5B5;
  --luck-text-color: #ff00ff;
  --hp-colour: #00C896;
  --atk-colour: #DC6034;
  --def-colour: #0078F6;
  --tas-colour: #0078F6;
  --skill-bg-color-one: #1BA3B4;
  --skill-bg-color-two: #18384C;
  --skill-border-color: #003040;
  --skill-heading-bg-color: #394955;
  --skill-heading-border-color: #00051C;
  --skill-heading-txt-color: #1BA3B4;
  --skill-ct-color: #00C6B1;
  --skill-break-color: #F9F871;
  --skill-break-txt-color: #00051C;
  --equip-rarity-star-color-one: #F9F871;
  --equip-rarity-star-color-two: #FFE171;
  --equip-ct-color: #518071;
  --equip-break-color: #FFE171;
  --lb-six-color: #a11875;
  --lb-seven-color: #a11844;
  --lb-five-color: #18a18f;
  --lb-one-color: #c2bdbd;
  --danger-color: #dc3434;
  --dark-color: #242331;
  --alert-success-color: #d1e7dd;
  --alert-success-txt-color: #0f5132;
  --alert-success-border-color: #badbcc;
  --alert-info-color: #cff4fc;
  --alert-info-txt-color: #055160;
  --alert-info-border-color: #b6effb;
  --alert-danger-color: #f8d7da;
  --alert-danger-txt-color: #842029;
  --alert-danger-border-color: #f5c2c7;
  --alert-warning-color: #fff3cd;
  --alert-warning-txt-color: #664d03;
  --alert-warning-border-color: #ffecb5;
  --footer-heading: #242331;
  // Define more variables as needed
}
