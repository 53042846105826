#gs-public-user-equip-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
  grid-auto-rows: minmax(100px, 115px);
  justify-content: center;
  gap: 5px 10px;
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: var(--accent-color);
  overflow-y: auto;
  padding: 15px 10px;
  border-radius: 0px 5px 5px 5px;
}
