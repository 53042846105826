.gs-user-unit-card-heading {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  margin-block-end: 0.53em;
  margin-top: 5px;

  svg:nth-child(1) {
    display: block;
    width: 85px;
    height: 45px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .gs-user-unit-title {
    width: 100%;
    
    h2 {
      width: 100%;
      height: 100%;
      margin-block-start: 0px;
      margin-block-end: 0px;
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  svg:nth-child(3) {
    display: block;
    width: 100px;
    height: 55px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
