.gs-equip-stats-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: nowrap;
  gap: 5px;
  list-style: none;
  margin: 0;
  width: calc(100% - 20px);
  justify-content: center;
  padding-inline-start: 0px;
  padding: 5px 10px;
  text-align: left;

  li {
    all: unset;
    display: inline-flex;
    justify-content: center;
    flex-grow: 1;
    gap: 5px;
    margin-right: 0px;

    .gs-stat-title {
      font-size: 18px;
      font-weight: 1000;
    }

    .gs-stat-container {
      display: inline-flex;
      position: relative;
      font-size: 18px;

      .gs-stat-data {
        display: block;
      }
    }
  }

  .gs-stat-hp {
    color: var(--hp-colour);
  }
  .gs-stat-atk {
    color: var(--atk-colour);
  }

  .gs-stat-def {
    color: var(--def-colour);
  }
}
