.gs-share-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 15px;

  h4 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .gs-share-unit-box, .gs-share-equip-box {
    width: auto;
    text-align: left;

    .gs-share-box-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      display: block;
    }
  }
}
