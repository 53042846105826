.gs-panel {
  background-color: var(--accent-color, #f0f0f0); // Default background color
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto; // Center the panel if its width is less than the container
}
