.gs-public-layout {
  display: flex;
  height: 100vh; /* Full viewport height */
  position: relative;
  align-items: flex-start;
  max-width: 100vw;

  .gs-public-content {
    flex-grow: 1;
    padding: 50px;
    overflow-y: auto;
    display: flex;
    width: calc(100% - 50px);
    flex-wrap: wrap;
    gap: 15px;
  }
}
