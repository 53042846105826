.gs-equip-passives-title {
  all: unset;
  display: block;
  margin-top: 4px;
  font-weight: 900;
  font-size: 18px;
}

.gs-equip-passives-container {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  // justify-content: center;
  align-items: start;
  width: 100%;
  justify-items: start;
  margin-top: 8px;

  .gs-panel {
    font-size: 14px;
    text-align: start;
    padding: 5px;
    margin: 0px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    box-sizing: border-box;
  }
}
