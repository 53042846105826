.gs-add-equip-card{
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0px;
  align-content: flex-start;
  background-color: var(--secondary-color);
  color: var(--text-color);
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;

  .gs-equip-artwork {
    width: 290px;
    height: 290px;
  }

  &-heading {
    width: 100%;
    margin-bottom: 10px;

    h2 {
      width: 100%;
      height: fit-content;
      margin-block-end: 0em;
    }
  }

  .gs-panel {
    width: 100%;
    background-color: var(--accent-color, #f0f0f0);
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: auto;
    flex-wrap: wrap;
  }
}
