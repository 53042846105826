.gs-dialog-box {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 202;

  &-content {

    .gs-dialog-box-close {
      position: absolute;
      color: var(--text-color);
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  button {
    padding: 5px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    color: var(--text-color);
    font-weight: 900;

    &:last-child {
      margin-right: 0;
    }
  }

  .primary {
    background-color: var(--danger-color);
  }

  .secondary {
    background-color: var(--dark-color);
  }
}
