.gs-equip-card-rarity-container {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0px;

  svg {
    font-size: 28px;
    color: var(--equip-rarity-star-color-one);
  }
}
