.gs-user-equip-preview {
  width: 85px;
  min-height: 100px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 85px;
  color: var(--text);
  position: relative;

  .gs-user-equip-lb {
    display: inline-flex;
    position: absolute;
    right: -5px;
    align-items: center;
    top: -10px;
    z-index: 100;

    span {
      display: block;
    }

    svg {
      width: 35px;
      height: 35px;
    }
  }

  &-icon {
    height: 80px;
    width: 100%;

    &:hover {
      cursor: zoom-in;
    }

    .gs-user-equip-preview-icon-img {
      margin: 0 auto;
      width: 75px;
      height: 75px;
      position: relative;
      border-radius: 16px;

      img {
        width: 75px;
        height: 75px;
      }
    }


    .gs-user-physical-boxshadow {
      box-shadow: 0px 0px 5px 1px rgb(214 18 18);
    }

    .gs-user-physical-boxshadow:hover {
      cursor: zoom-in;
      box-shadow: 0px 0px 10px 1px rgb(214 18 18);
    }

    .gs-user-magic-boxshadow {
      box-shadow: 0px 0px 5px 1px rgb(133 46 168);

    }

    .gs-user-magic-boxshadow:hover {
      cursor: zoom-in;
      box-shadow: 0px 0px 10px 1px rgb(133 46 168);
    }

    .gs-user-defence-boxshadow {
      box-shadow: 0px 0px 5px 1px rgb(43 146 202);
    }

    .gs-user-defence-boxshadow:hover {
      cursor: zoom-in;
      box-shadow: 0px 0px 10px 1px rgb(43 146 202);
    }

    .gs-user-support-boxshadow {
      box-shadow: 0px 0px 5px 1px rgb(196 182 46);
    }

    .gs-user-support-boxshadow:hover {
      cursor: zoom-in;
      box-shadow: 0px 0px 10px 1px rgb(196 182 46);
    }

    .gs-user-heal-boxshadow {
      box-shadow: 0px 0px 5px 1px rgb(33 155 107);
    }

    .gs-user-heal-boxshadow:hover {
      cursor: zoom-in;
      box-shadow: 0px 0px 10px 1px rgb(33 155 107);
    }
  }

  &-name {
    min-height: 20px;
    width: 85px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    color: var(--text-color);

    span {
      display: block;
      white-space: normal;
      word-wrap: break-word;
      width: 100%;
      overflow-wrap: break-word;
    }
  }
}
