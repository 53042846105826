.gs-unit-versions {
  all: unset;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 5px;

  li {
    all: unset;

    span {
      display: block;
      background: var(--accent-color);
      color: var(--text-color);
      opacity: 0.8;
      margin: 0 0px 5px 0;
      border-radius: 5px;
      padding: 0 4px;
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
      border: 0;
      outline: none;
      box-shadow: 0 2px rgba(0,0,0,0.2);
      -o-transition: all 0.1s;
      -ms-transition: all 0.1s;
      -moz-transition: all 0.1s;
      -webkit-transition: all 0.1s;
    }

    img {
      width: 30px;
    }
    
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
