.gs-default-layout {
  display: flex;
  position: relative;
  align-items: flex-start;

  .gs-default-content {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 180px);
    min-width: calc(100% - 140px);
    max-width: calc(100% - 140px);
    margin-left: 140px;

    .gs-main-section {
      flex-grow: 1;
      padding: 20px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      min-height: calc(100vh);
      gap: 15px;
      align-content: flex-start;
      width: 100%;
    }
  }
}

.gs-flash-messages {

}
