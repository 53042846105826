.gs-unit-card {
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0px;
  align-content: flex-start;
  background-color: var(--secondary-color);
  color: var(--text-color);
  border-radius: 5px;
  overflow-y: scroll;
  scrollbar-width: none;
  cursor: ns-resize;
  padding-bottom: 20px;
  
  .gs-unit-artwork {
    width: 290px;
    height: 290px;
  }

  h2 {
    width: 100%;
    height: fit-content;
    margin-block-end: 0.53em;
  }
}
