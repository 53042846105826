.gs-skill-box {
  width: 100%;

  .gs-skill-heading {
    display: flex;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 4px;

    .gs-skill-heading-text {
      all: unset;
      text-align: left;
      display: block;
      width: 50%;
      // margin-bottom: 5px;
      // margin-left: 2px;
      font-weight: bolder;
      padding-top: 3px;
    }

    .gs-skill-heading-actions {
      display: inline-flex;
      width: 50%;
      position: relative;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      align-content: center;
      justify-content: flex-end;

      .disabled {
        color: var(--accent-color);

        &:hover {
          color: var(--accent-color);
          cursor: not-allowed;
        }
      }

      svg {

      }

      svg:hover {
        color: var(--button-postive)
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
