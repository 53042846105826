.gs-panel {
  margin-bottom: 0px;

  .gs-equip-effect-txt {
    all: unset;
    line-height: 20px;
  }
}

.gs-equip-effect-stats-list {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;

  span {
    display: block;
    border-radius: 10px;
    padding: 5px 10px;
    flex-grow: 1;
    color: var(--text-colour);
    background: var(--accent-color);
    font-weight: bold;
  }

  .gs-equip-card-ct {
    // color: var(--equip-ct-color);
  }

  .gs-equip-card-break {
    // color: var(--equip-break-color);
  }
}
