.gs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 95%;
  position: relative;
  background-color: #e6cece;
  padding: 20px;
  gap: 20px;
}


.gs-main-content {
  flex-grow: 1;
  display: inline-flex;
  background-color: antiquewhite;
  padding: 5px 0px;
  border-radius: 10px;
  gap: 5px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.gs-extra-content {
  width: 33.5%;
  /* height: 100%; */
  display: inline-flex;
  background-color: antiquewhite;
  padding: 10px 10px;
  border-radius: 10px;
  min-width: 325px;
  max-width: 325px;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}
