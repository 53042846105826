.gs-skill-card {
  // background: linear-gradient(270deg, var(--skill-bg-color-two) 0%, var(--skill-bg-color-one) 10%, var(--skill-bg-color-one) 90%, var(--skill-bg-color-two) 100%);
  background: radial-gradient(circle, var(--skill-bg-color-one) 0%, var(--skill-bg-color-two) 200%);
  border: 1px solid var(--skill-border-color);
  border-radius: 5px;
  color: white;
  position: relative;
  font-size: 14px;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1px 0px;
  text-align: center;

  .gs-skill-card-heading {
    display: inline-flex;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;

    .gs-skill-type {
      position: absolute;
      text-align: center;
      font-weight: 900;
      background-color: #070606;
      top: 0px;
      left: -8px;
      border-radius: 5px 5px 5px 5px;
      padding: 1px;
      padding-left: 4px;
      padding-right: 4px;
    }

    .gs-skill-name {
      min-width: 60%;
      text-align: left;
      background-color: var(--skill-heading-bg-color);
      border: 1px solid var(--skill-heading-border-color);
      border-radius: 5px 5px 50px 5px;
      // padding-left: 4%;
      margin-left: 1px;
      padding-left: 2px;
    }

    .gs-skill-ct {
      background: var(--skill-ct-color);
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 12px;
      margin-left: 5px;
    }

    .gs-skill-break {
      position: absolute;
      right: 5px;
      background: var(--skill-break-color);
      color: var(--skill-break-txt-color);
      padding: 2.15px 5px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .gs-skill-details {
    display: inline-block;
    padding: 5px;
    min-height: 60px;
  }
}
