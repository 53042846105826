.gs-sidepanel {
  display: none;
}

.gs-sidepanel-open {
  display: flex!important;
  animation: slide-in-right 0.3s ease-in-out; /* Added animation */
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%); /* Initial position off-screen */
  }
  to {
    transform: translateX(0); /* Final position on-screen */
  }
}

.gs-sidepanel,
.gs-sidepanel-open {
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  width: 310px;
  height: 100vh;
  background-color: var(--primary-color);
  padding: 5px 10px;
  text-align: center;
  right: 0px;
  z-index: 100;

  .close-icon {
    position: absolute;
    color: var(--text-color);
    top: 10px; /* Adjust spacing as needed */
    right: 10px; /* Adjust spacing as needed */
    cursor: pointer;
  }

  .close-icon:hover {
    transform: scale(1.1); /* Optional hover effect */
  }

  .gs-sidepanel-content {
    display: flex;
    height: calc(100vh - 50px);
    width: 310px;
    margin-top: 35px;
    overflow: hidden;
  }
}
