.gs-copy-container {
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 5px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  input {
    color: #ffffff;
    height: 26px;
    width: 220px;
    padding: 2px 5px;
    border: 1px solid var(--accent-color);
    border-radius: 5px 0px 0px 5px;
    font-size: 14px;
    display: block;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: var(--secondary-color);

    &:focus {
      color: var(--text-input-color);
      background-color: var(--secondary-color);
      outline: 0;
    }
  }

  button {
    all: unset;
    color: var(--text-color);
    height: 22px;
    width: 72px;
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    padding: 4px 4px;
    justify-content: center;
    border: 1px solid var(--accent-color);
    border-radius: 0px 5px 5px 0px;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 2px;
    }
  }

  &:hover {
    box-shadow: #ffffff 0px 0px 10px;
  }
}
