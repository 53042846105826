.gs-add-equip-form-controls {
  display: flex;
  gap: 2px;

  .gs-add-equip-max-btn, .gs-add-equip-min-btn {
    all: unset;
    border-radius: 0px 0px 5px 5px;
    background: #48535a;
    padding: 2px 4px;
    display: flex;
    align-items: center;

    svg {
      font-size: 16px;
    }
  }
}
