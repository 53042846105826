.gs-user-unit-stats-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 5px;
  list-style: none;
  margin: 0;
  width: 100%;
  align-content: flex-start;
  justify-content: center;
  padding-inline-start: 0px;
  padding: 5px 0px;
  padding-right: 50px;
  text-align: left;

  li {
    all: unset;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-right: 20px;

    .gs-stat-title {
      width: 50px;
      font-size: 18px;
      font-weight: 1000;
    }

    .gs-stat-container {
      display: inline-flex;
      position: relative;
      font-size: 18px;

      .gs-stat-data {
        display: block;
        width: 50px;
      }

      .gs-tas-stat-data {
        display: inline-block;
        position: absolute;
        left: 48px;
        top: 3px;
        min-width: 40px;
        height: 14px;
        padding: 1px 1px;
        border-radius: 15px;
        background-color: var(--badge-color);
        color: var(--tas-text-color);
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        vertical-align: initial;
        white-space: nowrap;
      }

      .gs-luck-stat-data {
        display: inline-block;
        position: absolute;
        left: 94px;
        top: 3px;
        min-width: 40px;
        height: 14px;
        padding: 1px 1px;
        border-radius: 15px;
        background-color: var(--badge-color);
        color: var(--luck-text-color);
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        vertical-align: initial;
        white-space: nowrap;
      }
    }
  }

  .gs-stat-hp {
    color: var(--hp-colour);
  }
  .gs-stat-atk {
    color: var(--atk-colour);
  }

  .gs-stat-def {
    color: var(--def-colour);
  }
}
