#gs-unit-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
  grid-auto-rows: max-content;
  justify-content: center;
  gap: 5px 10px;
  // width: 1020px;
  min-width: 85px;
  background-color: var(--accent-color);
  overflow-y: auto;
  padding: 15px 10px;
  border-radius: 5px;
  max-height: 500px;
  max-width: 1020px;
  flex-grow: 1;
  scrollbar-width: none;
  cursor: ns-resize;
}
