#gs-user-box {
  width: 100%;

  .gs-user-box-nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 5px;
    // display: flex;
    // width: 100%;
    // min-width: 85px;
    // background-color: var(--accent-color);
    // overflow-y: auto;
    // padding: 15px 10px;
    // border-radius: 5px;
    // flex-direction: row;
    // flex-wrap: wrap;
    // gap: 10px;
    // height: fit-content;

    .gs-user-box-btn {
      all: unset;
      background: var(--accent-color);
      color: var(--text-color);
      opacity: 0.8;
      margin: 0 0px 5px 0;
      border-radius: 5px;
      padding: 0 20px;
      font-size: 16px;
      font-weight: bold;
      line-height: 49px;
      border: 0;
      outline: none;
      box-shadow: 0 2px rgba(0,0,0,0.2);
      -o-transition: all 0.1s;
      -ms-transition: all 0.1s;
      -moz-transition: all 0.1s;
      -webkit-transition: all 0.1s;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .gs-active-box-btn {
      opacity: 1;
      color: var(--active-text-color);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding-bottom: 5px;
      margin-bottom: 0;
      box-shadow: none;
    }

    .gs-user-box-delete-btn {
      all: unset;
      display: flex;
      background: var(--accent-color);
      color: var(--text-color);
      opacity: 0.8;
      margin: 0 0px 5px 0;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 49px;
      border: 0;
      outline: none;
      box-shadow: 0 2px rgba(0, 0, 0, 0.2);
      align-content: center;
      flex-direction: row;
      align-items: center;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      svg {
        font-size: 40px;
      }
    }

    .gs-active-box-delete-btn {
      opacity: 1;
      color: var(--atk-colour);
      box-shadow: none;
      border: 1px solid var(--atk-colour);
    }
  }
}
