.gs-footer {
  width: 100%;
  height: 300px;
  background: var(--accent-color);

  hr {
    border: none;
    height: 2px;
    background-color: var(--active-text-color);
    width: 95%;
    flex-grow: 1;
    margin-top: 44px;
    margin-bottom: 32px;
  }

  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 80px;
    column-gap: 40px;
    row-gap: 5px;
    padding-bottom: 32px;

    .gs-footer-section {
      width: 100%;
      max-width: 300px;

      .gs-footer-fluid {
        width: 100%;
        height: 32px;
      }

      .gs-footer-title {
        font-weight: 400;
        margin-bottom: 1rem;
        display: block;
        color: var(--text-color);
      }

      .gs-footer-section-heading {
        display: block;
        line-height: 30px;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        color: var(--footer-heading)
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        font-size: 15px;
        color: var(--text-color);
        gap: 12px;

        li {
          list-style: none;
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;

          a {
            all: unset;

            &:hover {
              text-decoration: underline;
              color: var(--text-hover-color);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
