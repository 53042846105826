.gs-add-equip-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* overflow-x: hidden; */
  gap: 10px;
  margin: 10px 0px;

  input[type="submit"] {
    width: 240px;
    height: 40px;
    border: none;
    outline: none;
    background: var(--submit-btn-color);
    color: var(--text-color);
    font-size: 22px;
    border-radius: 40px;
    text-align: center;
    box-shadow: 0 6px 20px -5px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
