.gs-user-equip-delete {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: -2px;
  top: -8px;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  svg {
    color: var(--atk-colour);
    width: 25px;
    height: 25px;
    background: var(--secondary-color);
    border-radius: 5px;

  }
}
