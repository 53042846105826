.gs-basic-form-field {
  width: 100%;

  &.gs-form-column {
    display: flex;
    flex-direction: column;

    .gs-form-label {
      margin-bottom: .5rem;
    }
  }

  &.gs-form-row {
    display: flex;
    align-items: center;
    gap: 10px; // Adjust the space between label and input as needed
  }

  .gs-form-label {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
  }

  .gs-form-input {
    all: unset;
    display: block;
    text-align: left;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-input-color);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0px;

    &:focus {
      color: var(--text-input-color);
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    }
  }
}
