.gs-user-equip-main-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;

  &-nav {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 5px;

    button {
      all: unset;
      padding: 4px;
      width: 64px;
      background-color: var(--accent-color);
      font-weight: 600;
    }

    button:hover {
      background-color: var(--accent-color-darker);
      cursor: pointer;
    }

    button:nth-child(1) {

      border-radius: 5px 0px 0px 5px;
      border-top: 1px outset var(--text-color);
      border-bottom: 1px inset var(--text-color);
      border-left: 1px outset var(--text-color);
    }

    button:nth-child(2) {

      border-top: 1px outset var(--text-color);
      border-bottom: 1px inset var(--text-color);
      border-left: 3px outset var(--text-color);
      border-right: 3px inset var(--text-color);
    }

    button:nth-child(3) {
      border-radius: 0px 5px 5px 0px;
      border-top: 1px outset var(--text-color);
      border-bottom: 1px inset var(--text-color);
      border-right: 1px outset var(--text-color);
    }
  }
}
