.gs-sidebar {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  min-width: 120px;
  width: 120px;
  height: 100vh;
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 5px 10px;
  text-align: center;
  z-index: 200;

  &-title {
    margin-bottom: 30px;
  }

  nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    position: relative;

    ul {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-wrap: wrap;
      gap: 20px;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        border-radius: 5px;
        position: relative;

        a, div {
          display: flex;
          align-items: center;
          gap: 10px;
          color: var(--text-color);
          text-decoration: none;
          width: 100%;
          padding: 10px 0px;

          svg {
            font-size: 1.25rem;
            margin-left: 5px;
          }
        }

        a:hover, div:hover {
          background-color: var(--secondary-color);
          cursor: pointer;
          border-radius: 5px;
          color: var(--text-hover-color);
        }

        &::after {
            content: '';
            display: block;
            width: 90%;
            height: 2px;
            background-color: var(--text-color);
            position: absolute;
            bottom: -5px;
            left: 5%;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

        &:hover::after {
          opacity: 1;
        }
      }
    }
  }

  &-bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 25px;

    .gs-sidebar-user-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-size: 14px;
      text-align: left;

      span {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
      }

      svg {
        position: relative;
      }

      svg:hover {
        cursor: pointer;
        color: var(--text-hover-color);
      }

      a {
        display: flex;
        color: var(--text-color);
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }

      a:hover {
        color: var(--text-hover-color);
      }
    }
  }
}
