.gs-add-unit-form-nav {
  margin-top: 15px;
  display: inline-flex;
  width: 100%;
  justify-content: center;

  button {
    all: unset;
    padding: 4px;
    width: 64px;
    background-color: var(--accent-color);
    font-weight: 600;
  }

  button:hover {
    background-color: var(--accent-color-darker);
    cursor: pointer;
  }

  button:nth-child(1) {
    border-radius: 5px 0px 0px 5px;
    border-top: 1px outset var(--text-color);
    border-bottom: 1px inset var(--text-color);
    border-left: 1px outset var(--text-color);
    border-right: 1px solid var(--text-color);
  }

  button:nth-child(2) {
    border-radius: 0px 5px 5px 0px;
    border-top: 1px outset var(--text-color);
    border-bottom: 1px inset var(--text-color);
    border-right: 1px outset var(--text-color);
    border-left: 1px solid var(--text-color);
  }
}

.gs-add-unit-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* overflow-x: hidden; */
  gap: 10px;
  margin: 10px 0px;

  h3 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 22px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  input[type="submit"] {
    width: 240px;
    height: 40px;
    border: none;
    outline: none;
    background: var(--submit-btn-color);
    color: var(--text-color);
    font-size: 22px;
    border-radius: 40px;
    text-align: center;
    box-shadow: 0 6px 20px -5px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
